/** @format */

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState("");
  const [pswd, setpswd] = useState("");
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    // Check the entered username and pswd against the expected values
    if (username === "user217" && pswd === "Ethit4NP8dr5") {
      window.location.href =
        "https://trello.com/invite/b/rWkLdKjW/ATTI8adba9e946d2a39af047164cba8b50f58E228085/client-217";
    } else if (username === "user218" && pswd === "34vnZTP7WZyy") {
      window.location.href =
        "https://trello.com/invite/b/MvEwR5eJ/ATTI6066a9b2fbf79e166555a493762fb0335E76F41F/client-218";
    } else if (username === "user219" && pswd === "1JbZg4M7cUhF") {
      window.location.href =
        "https://trello.com/invite/b/ihO32In4/ATTIac6baf0b0474c95e1d7d9b56a0c7389eBC473876/client-219";
    } else if (username === "user220" && pswd === "oX20LV77F2PK") {
      window.location.href =
        "https://trello.com/invite/b/0hYHcmN1/ATTI25663c889d9677292ddfad19155270daCE9AE775/client-220";
    } else if (username === "user221" && pswd === "lV45K57XzI9g") {
      window.location.href =
        "https://trello.com/invite/b/T2bQlVFB/ATTIe104a991fb07d5248c2ff52838343d47CF2E35BA/client-221";
    } else if (username === "user222" && pswd === "jq7rN24Y1C3P") {
      window.location.href =
        "https://trello.com/invite/b/rn4yp4qh/ATTIe4e59d51375a2c93037ba9850db7d15963B0B26A/client-222";
    } else if (username === "user223" && pswd === "9WciZr2166JJ") {
      window.location.href =
        "https://trello.com/invite/b/AIaFDm8g/ATTI0529bbde1bdb681e1e4f3abf2982342dDAC2D14C/client-223";
    } else if (username === "user224" && pswd === "19oxGnB8Qkgd") {
      window.location.href =
        "https://trello.com/invite/b/eVceS3Hi/ATTI4658e8ac598fc8e46b2751ab823003fa5D6987A3/client-224";
    } else if (username === "user225" && pswd === "eTT9s7B0786N") {
      window.location.href =
        "https://trello.com/invite/b/UfchPwTk/ATTI93fd85dd475dcb83acaa02be03aac3d32D7C517E/client-225";
    } else if (username === "user226" && pswd === "11Fm9E01WsHf") {
      window.location.href =
        "https://trello.com/invite/b/rfmVK4XH/ATTIfa1678e5a7068e7446d291ebf705454e0CBF592D/client-226";
    } else if (username === "uxline" && pswd === "homepage") {
      navigate("/");
    } else {
      setLoginError(true);
    }
  };

  return (
    <section id="login">
      <div className="main-login-div">
        <img className="lg1" src="icons/lg1.svg" alt="login icon" />

        <img className="lg2" src="icons/lg2.svg" alt="cactus icon" />

        <img className="lg3" src="icons/lg3.svg" alt="red graphic" />

        <div className="login-upper">
          <Link className="login-back" to="/">
            <svg
              id="svgexport-17_4_"
              data-name="svgexport-17 (4)"
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="24.621"
              viewBox="0 0 49 24.621"
            >
              <path
                id="Path_3019"
                data-name="Path 3019"
                d="M.324,138.895,11.46,127.759a1.114,1.114,0,1,1,1.57,1.57L3.8,138.572H47.886a1.114,1.114,0,0,1,0,2.227H3.8l9.232,9.232a1.114,1.114,0,1,1-1.57,1.57L.324,140.465a1.113,1.113,0,0,1,0-1.57Z"
                transform="translate(0 -127.369)"
              />
            </svg>
          </Link>

          <Link className="login-go" to="/signup">
            <p>რეგისტრაცია</p>
          </Link>
        </div>

        <div className="login-down">
          <h4 className="login-title">შესვლა</h4>

          <div className="login-labels">
            <label>
              <p>მომხარებელი</p>

              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />

              {loginError && (
                <img className="lg6" src="icons/error.svg" alt="X" />
              )}
            </label>
            <label>
              <p>პაროლი</p>

              <input
                type="password"
                value={pswd}
                onChange={(e) => setpswd(e.target.value)}
              />

              {loginError && (
                <img className="lg7" src="icons/error.svg" alt="X" />
              )}
            </label>
          </div>

          <button className="login-button" onClick={handleLogin}>
            შესვლა
          </button>
        </div>
      </div>
    </section>
  );
};

export default Login;
