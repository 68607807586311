/** @format */

import React from "react";

function Section4() {
  return (
    <section id="section4">
      <div className="main-div sct4">
        <div className="title-div fade-in-from-bottom2">
          <h2 className="h2title maxwidth800">
            არაფერი ახალი, უბრალოდ კარგად ვაკეთებთ
          </h2>

          <p className="h2subtitle maxwidth600">
            გარგებთ შენ და არა სტანდარტებს.
          </p>
        </div>

        <div className="sct4-div fade-in-from-bottom3">
          <div>
            <div className="sct4-svg">
              <svg
                className="noti-svg"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
              >
                <g strokeWidth="0.9">
                  <path
                    stroke="#0A0A30"
                    d="M12.29 3.398a5 5 0 00-5 5v2c0 .758-.441 1.505-1.005 2.012a3 3 0 002.005 5.232h8a3 3 0 002.006-5.232c-.564-.507-1.005-1.254-1.005-2.012v-2a5 5 0 00-5-5z"
                  />
                  <path
                    stroke="#265BFF"
                    strokeLinecap="round"
                    d="M14.68 20.312l-.042.01a9.713 9.713 0 01-4.67-.01"
                  />
                </g>
              </svg>
            </div>
            <p>მიიღე იმდენი დიზაინი რამდენიც გსურს</p>
          </div>

          <div>
            <div className="sct4-svg">
              <svg
                className="clock-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <rect
                  width="16"
                  height="16"
                  x="4"
                  y="4"
                  stroke="#0A0A30"
                  strokeWidth="0.9"
                  rx="2.075"
                />
                <path
                  stroke="#0A0A30"
                  strokeLinecap="round"
                  strokeWidth="0.9"
                  d="M12.021 12l2.325 2.325"
                />
                <path
                  className="rotate"
                  stroke="#265BFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="0.9"
                  d="M12.021 12V6.84"
                />
              </svg>
            </div>
            <p>მიიღე დიზაინი რამდენიმე სამუშაო დღეში</p>
          </div>

          <div>
            <div className="sct4-svg">
              <svg
                className="set-svg"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
              >
                <g
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="0.9"
                >
                  <path
                    stroke="#0A0A30"
                    d="M5.262 15.329l.486.842a1.49 1.49 0 002.035.55 1.486 1.486 0 012.036.529c.128.216.197.463.2.714a1.493 1.493 0 001.493 1.536h.979a1.486 1.486 0 001.485-1.493 1.493 1.493 0 011.493-1.471c.252.002.498.071.714.2a1.493 1.493 0 002.036-.55l.521-.857a1.493 1.493 0 00-.542-2.036 1.493 1.493 0 010-2.586c.71-.41.952-1.318.543-2.028l-.493-.85a1.493 1.493 0 00-2.036-.579 1.479 1.479 0 01-2.029-.543 1.428 1.428 0 01-.2-.714c0-.825-.668-1.493-1.492-1.493h-.98c-.82 0-1.488.664-1.492 1.486a1.485 1.485 0 01-1.493 1.493 1.521 1.521 0 01-.714-.2 1.493 1.493 0 00-2.036.542l-.514.858a1.486 1.486 0 00.543 2.035 1.486 1.486 0 01.543 2.036c-.13.226-.317.413-.543.543a1.493 1.493 0 00-.543 2.028v.008z"
                    clipRule="evenodd"
                  />
                  <path
                    stroke="#265BFF"
                    d="M12.044 10.147a1.853 1.853 0 100 3.706 1.853 1.853 0 000-3.706z"
                  />
                </g>
              </svg>
            </div>
            <p>ვმუშაობთ სანამ იდეალური არ გახდება დიზაინი</p>
          </div>
        </div>

        <div className="sct4-banner fade-in-from-bottom4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="30"
            viewBox="0 0 35 30"
            fill="none"
          >
            <path
              d="M22.3838 27.6777C23.5264 28.9961 25.3721 29.6992 27.4814 29.6992C31.6123 29.6992 34.249 26.9746 34.249 22.7559C34.249 18.625 31.5244 15.6367 27.6572 15.6367C26.8662 15.6367 25.9873 15.8125 25.1084 16.0762C24.5811 9.48438 27.833 4.03516 32.2275 2.36523L31.7881 0.871094C24.2295 3.77148 19.4834 11.1543 19.4834 19.8555C19.4834 22.668 20.5381 25.7441 22.3838 27.6777ZM0.499023 19.8555C0.499023 24.6895 3.22363 29.6992 8.49707 29.6992C12.54 29.6992 15.1768 26.9746 15.1768 22.7559C15.1768 18.625 12.4521 15.6367 8.67285 15.6367C7.88184 15.6367 7.00293 15.8125 6.12402 16.0762C5.59668 9.48438 8.84863 4.03516 13.2432 2.36523L12.7158 0.871094C5.24512 3.77148 0.499023 11.1543 0.499023 19.8555Z"
              fill="#EA552B"
            />
          </svg>

          <p>
            ბაუნსი გარწმუნებს, რომ
            <br />
            პროფები ისევ არსებობენ
          </p>
          <div className="bnr-logo">
            <img
              src="img/recent-work/icons/icon-5.svg"
              alt="recent work quote"
            />
          </div>

          <img
            className="bnr1 fade-in-from-bottom8"
            src="icons/bnr1.svg"
            alt="banner icon"
          />

          <img
            className="bnr2 fade-in-from-bottom7"
            src="icons/bnr2.svg"
            alt="banner icon"
          />
        </div>
      </div>
    </section>
  );
}

export default Section4;
