/** @format */

import React from "react";

function Section7() {
  return (
    <section id="section7">
      <img
        className="sct7svg1 fade-in-from-top3"
        src="icons/sct7-1.svg"
        alt="recent work"
        loading="lazy"
      />

      <img
        className="sct7svg2 fade-in-from-bottom39"
        src="icons/sct7-2.svg"
        alt="recent work"
        loading="lazy"
      />
      <div className="main-div sct7">
        <div className="title-div fade-in-from-bottom20">
          <h2 className="h2title maxwidth800">უახლესი პროექტები</h2>

          <p className="h2subtitle maxwidth800">
            არაფერი გარდა საუკეთესო ნამუშევრებისა.
          </p>
        </div>

        <div className="sct7-grid">
          <img
            className="fade-in-from-bottom21"
            src="img/recent-work/rcw.webp"
            alt="full project"
            loading="lazy"
          />
          <img
            className="fade-in-from-bottom22"
            src="img/recent-work/rcw2.webp"
            alt="responsive design"
            loading="lazy"
          />
          <img
            className="fade-in-from-bottom23"
            src="img/recent-work/rcw3.webp"
            alt="website design"
            loading="lazy"
          />
          <img
            className="fade-in-from-bottom24"
            src="img/recent-work/rcw4.webp"
            alt="dev logo"
            loading="lazy"
          />
        </div>

        <p className="normal-title text-align-center">
          ხშირად ასოცირებული ბრენდები
        </p>

        <div className="sct7-logos fade-in-from-bottom25">
          <img
            src="img/recent-work/icons/icon-1.svg"
            alt="uxline partner"
            loading="lazy"
          />
          <img
            src="img/recent-work/icons/icon-2.svg"
            alt="uxline partner"
            loading="lazy"
          />
          <img
            src="img/recent-work/icons/icon-3.svg"
            alt="uxline partner"
            loading="lazy"
          />
          <img
            src="img/recent-work/icons/icon-4.svg"
            alt="uxline partner"
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
}

export default Section7;
