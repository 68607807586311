/** @format */

import React from "react";
import { Link } from "react-router-dom";

function To5() {
  return <Link className="vaja-link" to="/vaja"></Link>;
}

export default To5;
