/** @format */

import React, { Component } from "react";
import { Link } from "react-scroll";
import To4 from "../components/to4";

class Section1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBar: false,
    };

    this.handleSidebar = this.handleSidebar.bind(this);
  }

  handleSidebar() {
    this.setState((prevState) => ({
      sideBar: !prevState.sideBar,
    }));
  }

  handleNavClick() {
    this.setState({
      sideBar: false,
    });
  }

  render() {
    return (
      <header className="header">
        <div className="navContainer">
          <span className="logo">
            <svg
              className="logo-image"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              width="311.60101318359375"
              height="152.7550048828125"
              viewBox="0 0 311.601 152.755"
            >
              <defs>
                <clipPath id="clip-path">
                  <path
                    id="Path_241"
                    data-name="Path 241"
                    d="M0,20.469H61.407V0H0Z"
                    fill="none"
                    className="bounce-logo-1"
                  ></path>
                </clipPath>
                <clipPath id="clip-path-3">
                  <path
                    id="Path_254"
                    data-name="Path 254"
                    d="M0,12.683H38.049V0H0Z"
                    fill="none"
                    stroke="#f29251"
                    strokeWidth="1"
                    className="bounce-logo-2"
                  ></path>
                </clipPath>
              </defs>
              <g
                id="Group_135"
                data-name="Group 135"
                transform="translate(-13454.182 781.356)"
              >
                <g
                  id="Group_133"
                  data-name="Group 133"
                  transform="translate(13454.182 -769.361)"
                >
                  <g
                    id="Group_59"
                    data-name="Group 59"
                    transform="translate(0)"
                  >
                    <g id="Group_50" data-name="Group 50">
                      <g
                        id="Mask_Group_1"
                        data-name="Mask Group 1"
                        transform="translate(0 93.573)"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="Rectangle_42"
                          data-name="Rectangle 42"
                          d="M3.5,0H57.882a3.5,3.5,0,0,1,3.5,3.5V30.689A30.689,30.689,0,0,1,30.689,61.378h0A30.689,30.689,0,0,1,0,30.689V3.5A3.5,3.5,0,0,1,3.5,0Z"
                          transform="translate(0 -40.909)"
                          fill="#f29251"
                          className="bounce-logo-3"
                        ></path>
                      </g>
                      <g
                        id="Mask_Group_1-2"
                        data-name="Mask Group 1"
                        transform="translate(0 67.256)"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="Rectangle_42-2"
                          data-name="Rectangle 42"
                          d="M3.5,0H57.882a3.5,3.5,0,0,1,3.5,3.5V30.689A30.689,30.689,0,0,1,30.689,61.378h0A30.689,30.689,0,0,1,0,30.689V3.5A3.5,3.5,0,0,1,3.5,0Z"
                          transform="translate(0 -41.445)"
                          fill="#f29251"
                          className="bounce-logo-4"
                        ></path>
                      </g>
                      <path
                        id="Path_243"
                        data-name="Path 243"
                        d="M30.7,61.407A30.7,30.7,0,1,0,0,30.7,30.7,30.7,0,0,0,30.7,61.407Z"
                        fill="#f29251"
                        className="bounce-logo-5"
                      ></path>
                    </g>
                  </g>
                </g>
                <path
                  id="Path_250"
                  data-name="Path 250"
                  d="M2.128,6.615v-50c0-10.062,3.659-16.77,12.806-16.77a3.789,3.789,0,0,1,1.22.152l1.525-4.574c1.525-4.116-.762-5.031-3.354-5.031-4.269,0-11.586-1.067-11.586-8.385V-79.52H16.306v.3c0,2.592,4.269,3.659,9.757,3.659,4.878,0,9.909,3.2,7.165,10.519L31.4-60.006l7.775,7.623c-3.506,1.982-3.506,5.793-3.506,8.69V28.721S2.128,21.708,2.128,6.615Zm19.971,9.3v-64.03a2.945,2.945,0,0,0-2.287-1.067c-3.811,0-4.116,4.574-4.116,7.47V5.548C15.7,10.274,17.221,15.915,22.1,15.915Z"
                  transform="translate(13525.146 -684.039)"
                  stroke="#1d1b1e"
                  strokeWidth="1"
                  className="bounce-logo-6"
                ></path>
                <path
                  id="Path_251"
                  data-name="Path 251"
                  d="M63.871,23.385c-2.439,3.354-5.946,5.336-9.147,5.336-8.995,0-12.654-7.013-12.654-16.922V-19.759H55.639V10.122c0,2.9.152,7.47,4.116,7.47a2.945,2.945,0,0,0,2.287-1.067V-21.436c0-17.837-12.5-23.325-12.5-36.131v-2.9H63.109v2.9c0,12.806,12.5,18.447,12.5,39.333V12.1c0,2.9,0,6.555,3.506,8.69l-7.775,7.928A11.464,11.464,0,0,1,63.871,23.385Z"
                  transform="translate(13524.773 -684.039)"
                  stroke="#1d1b1e"
                  strokeWidth="1"
                  className="bounce-logo-7"
                ></path>
                <path
                  id="Path_252"
                  data-name="Path 252"
                  d="M72.713-53.908,74.848-47.2A5.068,5.068,0,0,1,77.9-48.877c3.964,0,4.116,3.964,4.116,7.928V29.026H95.581V-43.541a37.379,37.379,0,0,0-.3-4.116,3.939,3.939,0,0,1,2.592-1.22c3.811,0,4.116,4.574,4.116,7.47V32.38c0,9.3-4.726,13.568-11.129,13.568H83.537l-4.574,9.6H90.7c13.873,0,24.85-7.013,24.85-23.63V-43.084c0-10.062-3.659-16.922-12.654-16.922-3.2,0-6.708,1.829-9.757,5.641-1.982-3.506-5.336-5.641-10.367-5.641C78.811-60.006,75.305-56.957,72.713-53.908Z"
                  transform="translate(13525.652 -684.654)"
                  stroke="#1d1b1e"
                  strokeWidth="1"
                  className="bounce-logo-8"
                ></path>
                <path
                  id="Path_253"
                  data-name="Path 253"
                  d="M121.955,28.721V-55.89c0-16.617,10.977-23.63,24.85-23.63h7.013l-4.574,9.6h-4.269c-4.878,0-9.452,2.744-9.452,12.044v.762a10.416,10.416,0,0,1,7.165-3.2c8.995,0,12.654,7.47,12.654,16.312V6.615c0,15.093-10.977,22.106-24.85,22.106Zm13.568-12.806c4.726,0,6.4-5.793,6.4-10.367V-41.407c0-3.811-.3-7.775-4.269-7.775a3.1,3.1,0,0,0-2.134.915Z"
                  transform="translate(13525.146 -684.039)"
                  stroke="#1d1b1e"
                  strokeWidth="1"
                  className="bounce-logo-9"
                ></path>
                <g
                  id="Mask_Group_1-3"
                  data-name="Mask Group 1"
                  transform="translate(13684.013 -668.001)"
                  clipPath="url(#clip-path-3)"
                >
                  <path
                    id="Rectangle_42-3"
                    data-name="Rectangle 42"
                    d="M2.166,0h33.7a2.166,2.166,0,0,1,2.166,2.166V19.015A19.015,19.015,0,0,1,19.015,38.031h0A19.015,19.015,0,0,1,0,19.015V2.166A2.166,2.166,0,0,1,2.166,0Z"
                    transform="translate(0 -25.68)"
                    fill="#f29251"
                    stroke="#f29251"
                    strokeWidth="1"
                    className="bounce-logo-10"
                  ></path>
                </g>
                <path
                  id="Path_255"
                  data-name="Path 255"
                  d="M93.793,10.122c0,2.9-.152,7.47-4.116,7.47a2.945,2.945,0,0,1-2.287-1.067V-79.52H73.822V12.1c0,2.9,0,6.555-3.506,8.69l7.775,7.928a11.464,11.464,0,0,0,7.47-5.336c2.439,3.354,5.946,5.336,9.147,5.336,8.995,0,12.653-7.013,12.653-16.922V-43.846c0-2.9,0-6.555,3.506-8.69l-7.775-7.928c-9.452,2.287-9.3,10.977-9.3,16.617Z"
                  transform="translate(13613.698 -701.336)"
                  stroke="#1d1b1e"
                  strokeWidth="1"
                  className="bounce-logo-11"
                ></path>
                <path
                  id="Path_256"
                  data-name="Path 256"
                  d="M201.688,29.026H215.1V-41.407c0-2.9.3-7.47,4.269-7.47a2.883,2.883,0,0,1,2.134,1.067V29.026h13.568V-43.389c0-2.9,0-6.555,3.506-8.69l-7.775-7.928a11.356,11.356,0,0,0-7.318,5.183c-2.592-3.2-6.1-5.183-9.147-5.183-8.995,0-12.654,7.013-12.654,16.922Z"
                  transform="translate(13526.403 -684.344)"
                  stroke="#1d1b1e"
                  strokeWidth="1"
                  className="bounce-logo-12"
                ></path>
              </g>
            </svg>
          </span>

          <ul className={`mainNav ${this.state.sideBar ? "open" : ""}`}>
            <li>
              <Link
                onClick={() => this.handleNavClick()}
                className="mainNavLink"
                activeClass="active"
                to="section7"
                spy={true}
                smooth={true}
                offset={-70}
                duration={2500}
              >
                უახლესი პროექტები
              </Link>
            </li>
            <li>
              <Link
                onClick={() => this.handleNavClick()}
                className="mainNavLink"
                activeClass="active"
                to="section10"
                spy={true}
                smooth={true}
                offset={-70}
                duration={2500}
              >
                ფასები
              </Link>
            </li>
            <li>
              <Link
                onClick={() => this.handleNavClick()}
                className="mainNavLink"
                activeClass="active"
                to="section11"
                spy={true}
                smooth={true}
                offset={-70}
                duration={2500}
              >
                ხშირად დასმული კითხვები
              </Link>
            </li>
            <li>
              <To4 />
            </li>
          </ul>

          <button
            onClick={this.handleSidebar}
            className={`navToggle ${this.state.sideBar ? "open" : ""}`}
          >
            <span />
            <span />
            <span />
          </button>
          <div
            onClick={this.handleSidebar}
            className={`overlay ${this.state.sideBar ? "open" : ""}`}
          />
        </div>
      </header>
    );
  }
}

export default Section1;
