/** @format */

import React, { useState, useEffect } from "react";

const CopyButton = () => {
  const [copied, setCopied] = useState({
    "iban-input-1": false,
    "iban-input-2": false,
  });

  const handleCopy = (inputId) => {
    const inputElement = document.getElementById(inputId);
    inputElement.select();
    document.execCommand("copy");
    setCopied((prevState) => ({ ...prevState, [inputId]: true }));
    setTimeout(() => {
      setCopied((prevState) => ({ ...prevState, [inputId]: false }));
    }, 2000);
  };

  const handleInputKeyDown = (event, inputId) => {
    if (
      ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "c") ||
      ((event.ctrlKey || event.metaKey) && event.keyCode === 67)
    ) {
      const selectedText = window.getSelection().toString();
      const inputElement = document.getElementById(inputId);
      if (selectedText === inputElement.value) {
        event.preventDefault();
        handleCopy(inputId);
      }
    }
  };

  useEffect(() => {
    const inputElements = document.querySelectorAll(".iban-input");
    inputElements.forEach((inputElement) => {
      const inputId = inputElement.getAttribute("id");
      inputElement.addEventListener("keydown", (event) =>
        handleInputKeyDown(event, inputId)
      );
    });

    return () => {
      inputElements.forEach((inputElement) => {
        const inputId = inputElement.getAttribute("id");
        inputElement.removeEventListener("keydown", (event) =>
          handleInputKeyDown(event, inputId)
        );
      });
    };
  });

  const handleInputFocus = (event) => {
    event.target.select();
  };

  return (
    <div className="main-iban">
      <div className="main-iban-item">
        <div className="iban-icon">
          <img src="icons/BOG.svg" alt="BOG" />
          <p>საქართველოს ბანკი</p>
        </div>
        <div className="iban-div">
          <input
            id="iban-input-1"
            className="iban-input"
            value="GE39BG0000000161320517GEL"
            readOnly
            onFocus={handleInputFocus}
          />
          <button
            className="iban-button"
            onClick={() => handleCopy("iban-input-1")}
          >
            <img src="icons/copy.svg" alt="copy" />
          </button>
          {copied["iban-input-1"] && (
            <div className="copied-text">
              <img src="icons/copied.svg" alt="copied" />
            </div>
          )}
        </div>
      </div>
      <div className="main-iban-item">
        <div className="iban-icon">
          <img src="icons/TBC.svg" alt="TBC" />
          <p>თბს ბანკი</p>
        </div>
        <div className="iban-div">
          <input
            id="iban-input-2"
            className="iban-input"
            value="GE52TB7000845061600039GEL"
            readOnly
            onFocus={handleInputFocus}
          />
          <button
            className="iban-button"
            onClick={() => handleCopy("iban-input-2")}
          >
            <img src="icons/copy.svg" alt="copy" />
          </button>
          {copied["iban-input-2"] && (
            <div className="copied-text">
              <img src="icons/copied.svg" alt="copied" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CopyButton;
