/** @format */

import React, { useState } from "react";

const PopupButton = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <div>
      <div className="packks-buttons">
        <button className="login-button packks-button" onClick={togglePopup}>
          დეტალურად
        </button>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://forms.gle/hYmUadWEkKxy3hwy7"
          className="login-button packks-button"
        >
          უკვე ჩავრიცხე
        </a>
      </div>
      {isPopupVisible && (
        <div className="popup-content">
          <button onClick={closePopup} className="popup-close">
            <img src="icons/pop-close.svg" alt="close" />
          </button>
          <div className="popup-text-div">
            <p>ჩარიცხეთ თანხა ანგარიშზე</p>
            <img src="icons/01.svg" alt="01" />
            <p>დააჭირეთ ღილაკს “უკვე ჩავრიცხე”</p>
            <img src="icons/02.svg" alt="02" />
            <p>შეავსეთ ფორმა</p>
            <img src="icons/03.svg" alt="03" />
            <p>
              24 საათის განმავლობაში ელ-ფოსტაზე მიიღებთ უნიკალური მომხმარებლის
              სახელს და პაროლს
            </p>

            <div className="p-star-div">
              <img src="icons/star.svg" alt="star" />
              <p>- სულ ესაა</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupButton;
