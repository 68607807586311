/** @format */

import React from "react";
import { Link } from "react-router-dom";
import CopyButton from "../components/CopyButton";
import PopupButton from "../components/PopupButton";

const Monthly = () => {
  return (
    <section id="login">
      <div className="main-login-div">
        <div className="login-down ppacks">
          <Link className="ppack-go" to="/">
            <svg
              id="svgexport-17_4_"
              data-name="svgexport-17 (4)"
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="24.621"
              viewBox="0 0 49 24.621"
            >
              <path
                id="Path_3019"
                data-name="Path 3019"
                d="M.324,138.895,11.46,127.759a1.114,1.114,0,1,1,1.57,1.57L3.8,138.572H47.886a1.114,1.114,0,0,1,0,2.227H3.8l9.232,9.232a1.114,1.114,0,1,1-1.57,1.57L.324,140.465a1.113,1.113,0,0,1,0-1.57Z"
                transform="translate(0 -127.369)"
              />
            </svg>
          </Link>

          <div className="packs-title">
            <h6>ჩარიცხეთ</h6>
            <p>1295.00₾</p>
          </div>

          <CopyButton />

          <PopupButton />
        </div>
      </div>
    </section>
  );
};

export default Monthly;
