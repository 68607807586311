/** @format */

import React from "react";
import { Link } from "react-scroll";

function Section2() {
  return (
    <section id="section2">
      <div className="main-sc2 fade-in-from-bottom">
        <h1>
          ჩვენს დიზაინს სულ
          <br />
          <span> </span>
          სხვა პეწი აქვს
        </h1>

        <p className="sc2-txt01">ჩვენს დიზაინს პეწი აქვს</p>

        <p className="sc2-txt1">სერვისი რომელიც გააფართოვებს შენს ბიზნესს.</p>

        <Link
          className="main-button"
          activeClass="active"
          to="section10"
          spy={true}
          smooth={true}
          offset={-70}
          duration={2500}
        >
          დაწყება
        </Link>

        <p className="sc2-txt2">
          სტილი რომელიც
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.454"
              height="16"
              viewBox="0 0 17.454 16"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_83"
                    data-name="Rectangle 83"
                    width="17.455"
                    height="16"
                    fill="#fff"
                  />
                </clipPath>
              </defs>
              <g
                id="svgexport-1_2_"
                data-name="svgexport-1 (2)"
                clipPath="url(#clip-path)"
              >
                <path
                  id="Path_1386"
                  data-name="Path 1386"
                  d="M16.945,2.964a4.787,4.787,0,0,0-7.6-1.244l-.621.568-.6-.548A4.743,4.743,0,0,0,3.989.428,4.707,4.707,0,0,0,.509,2.964,4.655,4.655,0,0,0,1.389,8.4l6.817,7.023a.727.727,0,0,0,1.043,0l6.807-7.009a4.665,4.665,0,0,0,.889-5.444Z"
                  fill="#ef87b6"
                />
              </g>
            </svg>
          </span>
          -ს ეხება
        </p>
      </div>

      <img
        className="hero-ab1 r1 fade-in-from-left"
        src="icons/h1r.svg"
        alt="left icon"
      />

      <img
        className="hero-ab1 r2 fade-in-from-left"
        src="icons/h1.svg"
        alt="right icon"
      />

      <img
        className="hero-ab2 fade-in-from-right"
        src="icons/h2.svg"
        alt="uxline.co icon"
      />
    </section>
  );
}

export default Section2;
