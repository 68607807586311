/** @format */

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./pages/main";
import Error404 from "./pages/Error404";
import ScrollToTop from "./components/top";
import TermsOfUse from "./pages/TermsOfUse";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Pricing from "./pages/pricing";
import Success from "./pages/success";
import Vaja from "./pages/vaja";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Monthly from "./pages/Monthly";
import Quarterly from "./pages/Quarterly";
import Yearly from "./pages/Yearly";

const App = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const handleScroll = () => {
      gsap.fromTo(
        ".fade-in-from-left",
        {
          opacity: 0,
          x: -50,
        },
        {
          opacity: 1,
          x: 0,
          stagger: 0.2,
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-left",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-left2",
        {
          opacity: 0,
          x: -50,
        },
        {
          opacity: 1,
          x: 0,
          stagger: 0.2,
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-left2",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-left3",
        {
          opacity: 0,
          x: -50,
        },
        {
          opacity: 1,
          x: 0,
          stagger: 0.2,
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-left3",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-right",
        {
          opacity: 0,
          x: 50,
        },
        {
          opacity: 1,
          x: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-left",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom2",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom2",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom3",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom3",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom4",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom4",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-nav",
        {
          opacity: 0,
          y: 500,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom4",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom4",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom5",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom5",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom6",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom6",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom7",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom7",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom8",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom7",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom9",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom9",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom10",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom10",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom11",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom11",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom12",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom12",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom13",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom13",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom14",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom14",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom15",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom14",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom16",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom14",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom17",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom17",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom18",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom17",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom19",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom17",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom20",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom20",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom21",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom21",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom22",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom21",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom23",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom23",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom24",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom23",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom25",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom25",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom26",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom26",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom27",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom27",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom28",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom28",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom29",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom29",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom30",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom30",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom31",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom31",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom32",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom32",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom33",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom33",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom34",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom34",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom35",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom35",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom36",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom36",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom37",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom37",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom38",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom38",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-bottom39",
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-bottom39",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-top",
        {
          opacity: 0,
          y: -50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-top",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-top2",
        {
          opacity: 0,
          y: -50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-top2",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-top3",
        {
          opacity: 0,
          y: -50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-top3",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-top4",
        {
          opacity: 0,
          y: -50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-top4",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-top5",
        {
          opacity: 0,
          y: -50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-top5",
            start: "top 80%",
          },
        }
      );

      gsap.fromTo(
        ".fade-in-from-top6",
        {
          opacity: 0,
          y: -50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".fade-in-from-top6",
            start: "top 80%",
          },
        }
      );
    };

    handleScroll();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/monthly" element={<Monthly />} />
          <Route path="/quarterly" element={<Quarterly />} />
          <Route path="/yearly" element={<Yearly />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/success" element={<Success />} />
          <Route path="/vaja" element={<Vaja />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </div>
      <div className="rslq fade-in-from-left fade-in-from-left2 fade-in-from-left3 fade-in-from-right fade-in-from-bottom fade-in-from-bottom2 fade-in-from-bottom3 fade-in-from-bottom4 fade-in-from-bottom5 fade-in-from-bottom6 fade-in-from-bottom7 fade-in-from-bottom8 fade-in-from-bottom9 fade-in-from-bottom10 fade-in-from-bottom11 fade-in-from-bottom12 fade-in-from-bottom13 fade-in-from-bottom14 fade-in-from-bottom15 fade-in-from-bottom16 fade-in-from-bottom17 fade-in-from-bottom18 fade-in-from-bottom19 fade-in-from-bottom20 fade-in-from-bottom21 fade-in-from-bottom22 fade-in-from-bottom23 fade-in-from-bottom24 fade-in-from-bottom25 fade-in-from-bottom26 fade-in-from-bottom27 fade-in-from-bottom28 fade-in-from-bottom29 fade-in-from-bottom30 fade-in-from-bottom31 fade-in-from-bottom32 fade-in-from-bottom33 fade-in-from-bottom34 fade-in-from-bottom35 fade-in-from-bottom36 fade-in-from-bottom37 fade-in-from-bottom38 fade-in-from-bottom39 fade-in-from-nav fade-in-from-top fade-in-from-top2 fade-in-from-top3 fade-in-from-top4 fade-in-from-top5 fade-in-from-top6"></div>
    </Router>
  );
};

export default App;
