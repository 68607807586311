/** @format */

import React from "react";
import { Link } from "react-scroll";

function Section6() {
  return (
    <section id="section6">
      <img
        className="sct6svg1  fade-in-from-left2"
        src="icons/sct6-1.svg"
        alt="Privileges of membership icon"
      />

      <img
        className="sct6svg2 fade-in-from-top2"
        src="icons/sct6-2.svg"
        alt="line icon"
      />
      <div className="main-div sct6">
        <div className="title-div fade-in-from-bottom13">
          <svg
            className="sct6-mrg"
            xmlns="http://www.w3.org/2000/svg"
            width="90"
            height="66"
            viewBox="0 0 90 66"
            fill="none"
          >
            <path
              d="M14.3765 32.2999C14.3765 35.7136 15.8491 38.5249 18.0579 40.9345C19.4636 42.474 21.1369 43.7458 22.6765 45.1514C23.0781 45.4861 23.5466 45.7539 23.8813 46.1555C24.8184 47.2264 24.5506 48.5651 23.2789 49.2345C22.8773 49.4353 22.4757 49.5022 22.074 49.703C17.3216 51.6442 13.841 54.7901 12.7031 60.078C12.5023 60.9481 12.3015 61.7514 12.1007 62.5546C11.9668 63.0901 11.766 63.6925 11.5652 64.228C11.2975 64.9642 10.762 65.4997 9.89185 65.4328C9.02169 65.3659 8.55315 64.8304 8.35234 64.0272C8.0846 63.0231 7.88379 61.9522 7.61605 60.8812C6.9467 58.1369 6.27735 55.3925 4.93865 52.849C4.06849 51.1087 2.93059 49.6361 1.25722 48.4982C-0.349227 47.4272 -0.416162 46.0885 1.05641 44.8168C2.26124 43.8127 3.66688 42.9426 4.93865 42.0055C7.95073 39.7966 9.69104 36.8515 9.95878 33.0362C10.0927 32.4338 10.0927 31.6306 10.2265 30.8273C10.3604 29.2878 10.5612 29.087 11.8999 29.0201C13.2386 28.8862 13.9749 29.4217 14.2426 30.6265C14.3765 31.162 14.3096 31.7644 14.3765 32.2999Z"
              fill="#F7D046"
            />
            <path
              d="M71.4901 42.4114C71.285 40.6333 71.2166 38.7868 70.943 37.0771C70.4643 34.2048 69.0965 31.948 66.4978 30.5118C64.3093 29.3492 62.1209 28.3234 59.8641 27.2292C59.1802 26.8872 58.4279 26.6137 57.7441 26.2717C57.197 25.9982 56.855 25.5195 57.0602 24.904C57.197 24.4937 57.6757 24.2201 58.0176 23.8782C58.086 23.8098 58.2228 23.8098 58.3596 23.8098C63.7622 22.7156 67.3868 19.2277 69.8488 14.5773C72.0372 10.5424 73.8153 6.30236 75.7986 2.19906C76.0721 1.58356 76.3457 0.968065 76.6876 0.489347C76.8928 0.215794 77.4399 -0.0577596 77.7818 0.0106287C78.1238 0.0790171 78.5341 0.489347 78.6025 0.899677C78.7393 1.31001 78.6709 1.85711 78.6025 2.33583C78.4657 3.29327 78.329 4.2507 78.1238 5.27653C77.3031 9.31144 78.1238 13.2096 78.9445 17.1077C79.3548 18.8174 80.3806 20.0484 81.8851 20.8691C83.8 21.8949 85.7833 22.5788 87.9033 23.0575C88.5872 23.1943 89.5446 23.1943 89.613 24.2201C89.6814 25.3143 88.724 25.5879 87.9717 25.793C84.6891 26.6137 82.3639 28.8021 80.2438 31.3325C78.0554 33.9312 76.3457 36.8719 75.2515 40.0862C74.636 41.8643 74.0889 43.6424 73.4734 45.3521C73.3366 45.694 73.3366 46.036 73.1314 46.2411C72.8579 46.5831 72.3792 47.0618 72.1056 46.9934C71.7637 46.925 71.4901 46.3779 71.285 45.9676C71.1482 45.694 71.2166 45.3521 71.2166 45.0102C71.2166 44.1211 71.2166 43.2321 71.2166 42.4114C71.3533 42.4114 71.4217 42.4114 71.4901 42.4114Z"
              fill="#EA552B"
            />
          </svg>

          <h2 className="h2title maxwidth600">პრივილეგიები</h2>

          <p className="h2subtitle maxwidth800">
            პაკეტი იმდენად სრულყოფილია, რომ არასდროს მიმართავთ სხვას
            დიზაინისთვის. გაზვიადების გარეშე...
          </p>

          <Link
            className="main-button"
            activeClass="active"
            to="section10"
            spy={true}
            smooth={true}
            offset={-70}
            duration={2000}
          >
            ფასები
          </Link>
        </div>

        <div className="sct6-grid">
          <div className="notmal-txt-div fade-in-from-bottom14">
            <div className="sct6-svg">
              <svg
                className="infi-svg"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
              >
                <path
                  stroke="#0A0A30"
                  strokeWidth="0.9"
                  d="M9.743 10.25c3.213 1.96 5.017 4.676 7.248 4.676 2.588 0 2.791-4.8.518-5.668-3.107-1.187-5.178 3.719-8.284 5.03-1.415.677-3.41 1.014-4.09-1.14-.251-.797-.13-1.65.133-2.442v0c.425-1.278 2.132-1.66 3.35-1.081.304.144.668.346 1.125.625z"
                  strokeDashoffset="100"
                  strokeDasharray="100"
                />
              </svg>
            </div>

            <p className="normal-title">დიზაინის დაფა</p>
            <p className="normal-txt">
              მოთხოვნების რაოდენობა შეუზღუდავია - ამატე ულიმიტოდ!
            </p>
          </div>
          <div className="notmal-txt-div fade-in-from-bottom15">
            <div className="sct6-svg">
              <svg
                className="speed-svg"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
              >
                <path
                  fill="#0A0A30"
                  d="M13.295 10.769l2.552-5.787-7.979 7.28 3.254.225-3.353 6.362 8.485-7.388-2.959-.692z"
                />
              </svg>
            </div>

            <p className="normal-title">უსწრაფესი მიწოდება</p>
            <p className="normal-txt">მიიღე სწრაფად, ერთ ჯერზე ერთი</p>
          </div>
          <div className="notmal-txt-div fade-in-from-bottom16">
            <div className="sct6-svg">
              <svg
                className="fees-svg"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
              >
                <path
                  className="fees-path"
                  stroke="#0A0A30"
                  strokeWidth="0.9"
                  d="M10.524 4.614a2 2 0 012.952 0l.459.502a2 2 0 001.565.648l.68-.03a2 2 0 012.087 2.087l-.03.68a2 2 0 00.648 1.564l.501.46a2 2 0 010 2.95l-.502.46a2 2 0 00-.648 1.565l.03.68a2 2 0 01-2.086 2.087l-.68-.03a2 2 0 00-1.565.648l-.46.501a2 2 0 01-2.95 0l-.46-.502a2 2 0 00-1.565-.648l-.68.03a2 2 0 01-2.087-2.086l.03-.68a2 2 0 00-.647-1.565l-.502-.46a2 2 0 010-2.95l.502-.46A2 2 0 005.764 8.5l-.03-.68A2 2 0 017.82 5.734l.68.03a2 2 0 001.564-.647l.46-.502z"
                />
                <path
                  stroke="#265BFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="0.9"
                  d="M13.873 10.004l-3.746 3.992"
                />
                <circle cx="10.127" cy="10.004" r="1.074" fill="#265BFF" />
                <circle cx="13.873" cy="13.995" r="1.074" fill="#265BFF" />
              </svg>
            </div>

            <p className="normal-title">ფიქსირებული ტარიფი</p>
            <p className="normal-txt">
              არანაირი თანხის ზრდა! ყოველთვე იგივე თანხა
            </p>
          </div>
          <div className="notmal-txt-div fade-in-from-bottom17">
            <div className="sct6-svg">
              <svg
                className="str-svg"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
              >
                <path
                  stroke="#0A0A30"
                  strokeWidth="0.9"
                  d="M11.081 5.141c.347-.807 1.491-.807 1.838 0l1.274 2.97a1 1 0 00.828.601l3.218.295c.875.08 1.229 1.168.568 1.748l-2.43 2.13a1 1 0 00-.316.972l.714 3.152c.194.857-.732 1.53-1.487 1.08l-2.776-1.653a1 1 0 00-1.024 0l-2.776 1.653c-.755.45-1.681-.223-1.487-1.08l.715-3.152a1 1 0 00-.317-.973l-2.43-2.13c-.66-.579-.307-1.667.568-1.747l3.218-.295a1 1 0 00.828-.601l1.274-2.97z"
                />
              </svg>
            </div>

            <p className="normal-title">უმაღლესი ხარისხი</p>
            <p className="normal-txt">
              უმაღლესი დონის დიზაინი მაშინ როცა გჭირდება
            </p>
          </div>
          <div className="notmal-txt-div fade-in-from-bottom18">
            <div className="sct6-svg">
              <svg
                className="st-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#000"
                  strokeLinecap="round"
                  strokeWidth="0.9"
                  d="M6 8.746h12M6 15.317h12"
                />
                <circle
                  className="slidersss"
                  cx="7.5"
                  cy="8.746"
                  r="0.9"
                  fill="#265BFF"
                  stroke="#265BFF"
                  strokeWidth="0.9"
                />
                <circle
                  className="slidersss-2"
                  cx="16.5"
                  cy="15.254"
                  r="0.9"
                  fill="#265BFF"
                  stroke="#265BFF"
                  strokeWidth="0.9"
                />
              </svg>
            </div>

            <p className="normal-title">მოქნილი</p>
            <p className="normal-txt">
              გადახედე დიზაინს და მოითხოვე ცვლილებების შეტანა ულიმიტოდ
            </p>
          </div>
          <div className="notmal-txt-div fade-in-from-bottom19">
            <div className="sct6-svg">
              <svg
                className="hrt-svg"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
              >
                <g className="hrt2-svg" strokeWidth="0.9">
                  <path
                    stroke="#0A0A30"
                    d="M11.515 6.269l.134.132a.5.5 0 00.702 0l.133-.132A4.44 4.44 0 0115.599 5c.578 0 1.15.112 1.684.33a4.41 4.41 0 011.429.939c.408.402.733.88.954 1.406a4.274 4.274 0 010 3.316 4.331 4.331 0 01-.954 1.405l-6.36 6.259a.5.5 0 01-.702 0l-6.36-6.259A4.298 4.298 0 014 9.333c0-1.15.464-2.252 1.29-3.064A4.439 4.439 0 018.401 5c1.168 0 2.288.456 3.114 1.269z"
                  />
                  <path
                    stroke="#265BFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.5 7.5c.802.304 1.862 1.43 2 2"
                  />
                </g>
              </svg>
            </div>

            <p className="normal-title">უ-ნი-კა-ლუ-რი</p>
            <p className="normal-txt">დიზაინი შექმნილი მხოლოდ შენთვის</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section6;
