/** @format */

import React, { useState, useEffect } from "react";
import To3 from "../components/to3";

const Navbar = () => {
  const navLinks = [
    { label: "", sectionId: "section2" },
    { label: "პრივილეგიები", sectionId: "section6" },
    { label: "უახლესი პროექტები", sectionId: "section7" },
    { label: "რას ვაკეთებთ", sectionId: "section9" },
    { label: "ფასები", sectionId: "section10" },
    { label: "FAQ", sectionId: "section11" },
  ];

  const [activeSection, setActiveSection] = useState("");
  const [clickedLink, setClickedLink] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      const sections = document.querySelectorAll("section");
      let currentActiveSection = "";

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          currentPosition >= sectionTop - sectionHeight / 2 &&
          currentPosition < sectionTop + sectionHeight
        ) {
          currentActiveSection = section.id;
        }
      });

      if (clickedLink === "") {
        setActiveSection(currentActiveSection);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [clickedLink]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });

      setClickedLink(sectionId);
      setTimeout(() => {
        setClickedLink("");
      }, 300);
    }
  };

  return (
    <nav className="fade-in-from-nav">
      <ul>
        {navLinks.map((link) => (
          <li key={link.sectionId}>
            <a
              href={`#${link.sectionId}`}
              className={activeSection === link.sectionId ? "active" : ""}
              onClick={(event) => {
                event.preventDefault();
                scrollToSection(link.sectionId);
              }}
            >
              {link.label}
            </a>
          </li>
        ))}
      </ul>
      <div className="login-navlink">
        <To3 />
      </div>
    </nav>
  );
};

const Nav = () => {
  return (
    <div>
      <Navbar />
    </div>
  );
};

export default Nav;
