/** @format */

import React from "react";
import Section10 from "./Section10";
import { Link } from "react-router-dom";

function Pricing() {
  return (
    <section id="only-pricing">
      <div className="onp">
        <Link className="link-back main-button" to="/">
          <svg
            id="svgexport-17_4_"
            data-name="svgexport-17 (4)"
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="24.621"
            viewBox="0 0 49 24.621"
          >
            <path
              id="Path_3019"
              data-name="Path 3019"
              fill="#fff"
              d="M.324,138.895,11.46,127.759a1.114,1.114,0,1,1,1.57,1.57L3.8,138.572H47.886a1.114,1.114,0,0,1,0,2.227H3.8l9.232,9.232a1.114,1.114,0,1,1-1.57,1.57L.324,140.465a1.113,1.113,0,0,1,0-1.57Z"
              transform="translate(0 -127.369)"
            />
          </svg>
        </Link>
      </div>

      <style>{`.sct10svg2 { display: none; }`}</style>

      <Section10 />
    </section>
  );
}

export default Pricing;
