/** @format */

import React, { useEffect, useRef } from "react";
import RotateOnScroll from "../components/rotation";
import Marquee from "../components/Marquee";

const Section3 = () => {
  const containerRef = useRef(null);
  const imagesRef = useRef([]);
  const animationFrameRef = useRef(null);
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const images = imagesRef.current;

    const handleScroll = () => {
      if (debounceTimeoutRef.current) {
        cancelAnimationFrame(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = requestAnimationFrame(() => {
        const scrollPercentage =
          (window.scrollY /
            (document.documentElement.scrollHeight - window.innerHeight)) *
          100;
        const moveAmount =
          (container.scrollWidth - container.clientWidth) *
          (scrollPercentage / 50);

        container.scrollTo(moveAmount, 0);

        images.forEach((image) => {
          image.style.transform = `translateX(-${moveAmount}px)`;
        });
      });
    };

    const handleResize = () => {
      handleScroll();
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    const debounceTimeout = debounceTimeoutRef.current;
    const animationFrame = animationFrameRef.current;

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
      cancelAnimationFrame(animationFrame);
      clearTimeout(debounceTimeout);
    };
  }, []);

  return (
    <section id="section3">
      <RotateOnScroll />

      <div className="scroll-container" ref={containerRef}>
        <div
          className="scrollable-image"
          ref={(el) => (imagesRef.current[0] = el)}
          style={{ backgroundImage: "url(img/hero/photo-1.webp)" }}
        />
        <div
          className="scrollable-image"
          ref={(el) => (imagesRef.current[1] = el)}
          style={{ backgroundImage: "url(img/hero/photo-2.webp)" }}
        />
        <div
          className="scrollable-image"
          ref={(el) => (imagesRef.current[2] = el)}
          style={{ backgroundImage: "url(img/hero/photo-3.webp)" }}
        />
        <div
          className="scrollable-image"
          ref={(el) => (imagesRef.current[3] = el)}
          style={{ backgroundImage: "url(img/hero/photo-4.webp)" }}
        />
        <div
          className="scrollable-image"
          ref={(el) => (imagesRef.current[4] = el)}
          style={{ backgroundImage: "url(img/hero/photo-5.webp)" }}
        />
      </div>

      <Marquee />
    </section>
  );
};

export default Section3;
