/** @format */

import React from "react";
import { Link } from "react-scroll";
import To3 from "../components/to3";
import To1 from "../components/to1";
import To5 from "../components/to5";

function Section12() {
  return (
    <footer id="section12">
      <div className="main-div sct12">
        <div className="title-div fade-in-from-bottom36">
          <h2 className="h2title maxwidth800">
            გაიგე რამდენად შენია
            <br />
            ბაუნსი. (ნამდვილად შენია!)
          </h2>

          <p className="h2subtitle maxwidth600">
            მოიწყვე საიტის ტური, იპოვე დამალული გვერდი და მიიღე საჩუქარი
          </p>

          <Link
            className="main-button"
            activeClass="active"
            to="section10"
            spy={true}
            smooth={true}
            offset={-70}
            duration={1000}
          >
            დაწყება
          </Link>
        </div>

        <div className="footer-logos fade-in-from-bottom37">
          <img src="img/hero/marquee/photo-1.svg" alt="name" />
          <img src="img/hero/marquee/photo-8.svg" alt="name" />
          <img src="img/hero/marquee/photo-3.svg" alt="name" />
          <img src="img/hero/marquee/photo-7.svg" alt="name" />
          <img src="img/hero/marquee/photo-6.svg" alt="name" />
        </div>

        <div className="footer-bottom fade-in-from-bottom38">
          <div>
            <img
              className="logo-image"
              src="./icons/footer-logo.svg"
              alt="uxline logo"
            />
          </div>

          <div className="footer-links">
            <div>
              <Link
                activeClass="active"
                to="section7"
                spy={true}
                smooth={true}
                offset={-70}
                duration={2000}
              >
                უახლესი პროექტები
              </Link>

              <Link
                activeClass="active"
                to="section10"
                spy={true}
                smooth={true}
                offset={-70}
                duration={2000}
              >
                ფასები
              </Link>

              <a
                href="https://calendly.com/bounce-ge/15min"
                target="_blank"
                rel="noreferrer"
              >
                კონტაქტი
              </a>
            </div>
            <div>
              <To3 />

              <Link
                activeClass="active"
                to="section2"
                spy={true}
                smooth={true}
                offset={-70}
                duration={2500}
              >
                საწყისი
              </Link>

              <To1 />

              <div className="footer-social-links">
                <a
                  target="blank_"
                  href="https://www.facebook.com/www.bounce.ge/"
                >
                  <img src="./icons/fb.svg" alt="facebook" />
                </a>
                <a target="blank_" href="https://dribbble.com/BounceGe">
                  <img src="./icons/db.svg" alt="dribbble" />
                </a>
                <a target="blank_" href="https://www.behance.net/bouncege">
                  <img src="./icons/be.svg" alt="behance" />
                </a>
                <a target="blank_" href="https://bouncege.gumroad.com/">
                  <img src="./icons/gum.svg" alt="gumroad" />
                </a>
                <a target="blank_" href="https://calendly.com/bounce-ge/15min">
                  <img src="./icons/cl.svg" alt="calendly" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img className="sct12svg" src="icons/sct12.svg" alt="footer links" />

      <To5 />
    </footer>
  );
}

export default Section12;
