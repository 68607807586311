/** @format */

import React from "react";
import Faq from "../components/faq";

function Section11() {
  return (
    <section id="section11">
      <img
        className="sct11svg1 fade-in-from-bottom33"
        src="icons/sct11-1.svg"
        alt="uxline faq icon"
      />

      <img
        className="sct10svg2 fade-in-from-top6"
        src="icons/sct10-2.svg"
        alt="faq road"
      />

      <img
        className="sct11svg2 fade-in-from-bottom34"
        src="icons/sct11-2.svg"
        alt="questions?"
      />
      <div className="main-div sct11">
        <div className="title-div">
          <h2 className="h2title maxwidth800">ხშირად დასმული კითხვები</h2>
        </div>
        <Faq />
      </div>
    </section>
  );
}

export default Section11;
