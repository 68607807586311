/** @format */

import React from "react";

function Section8() {
  return (
    <section id="section8">
      <img
        className="sct8svg1 fade-in-from-top4"
        src="icons/sct8-1.svg"
        alt="ad line"
      />
      <div className="main-div sct8">
        <div className="sct8-div fade-in-from-bottom26">
          <div>
            <img
              className="sct8svg2"
              src="icons/sct8-2.svg"
              alt="graphic icon"
            />

            <h3>გაიზიარე</h3>

            <p className="normal-txt">
              მიიღე წვდომა ჩვენს დიზაინ აღჭურვილობაზე: ფონტები, ფოტოები და სხვა
            </p>

            <a
              href="https://bouncege.gumroad.com/"
              target="blank_"
              className="main-button"
            >
              გადადი
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section8;
