/** @format */

import React from "react";
import { Link } from "react-router-dom";

function Section10() {
  return (
    <section id="section10">
      <img
        className="sct10svg1 fade-in-from-bottom29"
        src="icons/sct10-1.svg"
        alt="membership plan icon"
      />

      <img
        className="sct10svg2 fade-in-from-top5"
        src="icons/sct10-2.svg"
        alt="lines to plans"
      />

      <img
        className="sct10svg3 fade-in-from-bottom30"
        src="icons/sct10-3.svg"
        alt="list what we can do"
      />
      <div className="main-div sct10">
        <div className="title-div fade-in-from-bottom31">
          <h2 className="h2title maxwidth800">სერვისის ფასები</h2>

          <p className="h2subtitle maxwidth600">
            აირჩიე შენთვის ხელსაყრელი გეგმა.
          </p>

          <div className="sct10svg4-div">
            <img
              className="sct10svg4"
              src="icons/sct10-4.svg"
              alt="road to our plans"
            />
          </div>
        </div>

        <div className="sct10-main-grid fade-in-from-bottom32">
          <div className="sct10-main-item">
            <div className="sct10-main-item-top">
              <img
                className="sct10svg5"
                src="icons/sct10-5.svg"
                alt="most popular plan"
              />
              <div className="sct10-tag">
                <div>პოპულარული</div>
              </div>
              <div className="sct10-top-item-top">
                <p className="sct10-title">ყოველთვიური</p>
                <p className="sct10-subtitle">
                  შეაჩერე სერვისის გამოწერა ნებიმიერ დროს
                </p>
              </div>

              <div className="sct10-top-item-bottom">
                <p className="sct10-price">₾1,295/თვე</p>
                <p className="sct10-price-subtitle">შეაჩერე ნებისმისერ დროს</p>

                <Link to="/monthly" className="main-button">
                  დაწყება
                </Link>

                <a
                  href="https://forms.gle/ee2STLk44yL6PJU38"
                  target="blank_"
                  className="book-a-call-button"
                >
                  7 დღე უფასოდ
                </a>
              </div>
            </div>
            <div className="sct10-main-item-bottom">
              <div className="sct10-item-bottom">
                <p className="sct10-bottom-title">რა შედის:</p>

                <ul className="sct10-bottom-ul">
                  <li>ერთი მოთხოვნა ერთ ჯერზე</li>
                  <li>ულიმიტო ბრენდები</li>
                  <li>ულიმიტო მომხმარებლები</li>
                  <li>ულიმიტო სტოკ ფოტოები</li>
                  <li>ულიმიტო რევიზია</li>
                  <li>სერვისის ნებისმიერ დროს გაუქმება</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="sct10-main-item">
            <div className="sct10-main-item-top">
              <div className="sct10-top-item-top">
                <p className="sct10-title">კვარტალური</p>
                <p className="sct10-subtitle">დაზოგე 100₾ თვიურად.</p>
              </div>

              <div className="sct10-top-item-bottom">
                <p className="sct10-price">₾1,195/თვე</p>
                <p className="sct10-price-subtitle">გადაიხადე 3 თვის</p>
                <Link to="/quarterly" className="main-button">
                  დაწყება
                </Link>
                <a
                  href="https://forms.gle/ee2STLk44yL6PJU38"
                  target="blank_"
                  className="book-a-call-button"
                >
                  7 დღე უფასოდ
                </a>
              </div>
            </div>
            <div className="sct10-main-item-bottom">
              <div className="sct10-item-bottom">
                <p className="sct10-bottom-title">რა შედის:</p>

                <ul className="sct10-bottom-ul">
                  <li>ერთი მოთხოვნა ერთ ჯერზე</li>
                  <li>ულიმიტო ბრენდები</li>
                  <li>ულიმიტო მომხმარებლები</li>
                  <li>ულიმიტო სტოკ ფოტოები</li>
                  <li>ულიმიტო რევიზია</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="sct10-main-item">
            <div className="sct10-main-item-top">
              <div className="sct10-top-item-top">
                <p className="sct10-title">წლიური</p>
                <p className="sct10-subtitle">დაზოგე 300₾ თვიურად.</p>
              </div>

              <div className="sct10-top-item-bottom">
                <p className="sct10-price">₾995/თვე</p>
                <p className="sct10-price-subtitle">გადაიხადე 12 თვის</p>
                <Link to="/yearly" className="main-button">
                  დაწყება
                </Link>
                <a
                  href="https://forms.gle/ee2STLk44yL6PJU38"
                  target="blank_"
                  className="book-a-call-button"
                >
                  7 დღე უფასოდ
                </a>
              </div>
            </div>
            <div className="sct10-main-item-bottom">
              <div className="sct10-item-bottom">
                <p className="sct10-bottom-title">რა შედის:</p>

                <ul className="sct10-bottom-ul">
                  <li>ერთი მოთხოვნა ერთ ჯერზე</li>
                  <li>ულიმიტო ბრენდები</li>
                  <li>ულიმიტო მომხმარებლები</li>
                  <li>ულიმიტო სტოკ ფოტოები</li>
                  <li>ულიმიტო რევიზია</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="sct10-main-item">
            <div className="sct10-r-top">
              <div>
                <img src="img/pricing/icon-1.svg" alt="iconname" />
              </div>
              <p className="sct10-title">ჩანიშნე ზარი</p>
              <p className="sct10-subtitle">
                გაიგეთ როგორ დაგეხმარებათ ბაუნსი დიზაინის შექმნაში.
              </p>
              <a href="https://calendly.com/bounce-ge/15min" target="blank_">
                ზარის დაჯავშნა
              </a>
            </div>
            <div className="sct10-r-bottom">
              <img src="img/pricing/icon-2.svg" alt="iconname" />
              <p className="sct10-title">გაუზიარე სხვას და გამოიმუშავე</p>
              <p className="sct10-subtitle">მიიღეთ 5% ყოველ რეფერალზე.</p>

              <Link to="/login">შემოგვიერთდი</Link>
            </div>
          </div>
        </div>

        <div className="sct10-addon">
          <div>დამატება</div>

          <h2>1 x 2</h2>

          <p>ორი მოთხოვნა ერთ ჯერზე. მოითხოვს სერვისის გამოწერას.</p>

          <h3>₾649/თვე</h3>
        </div>
      </div>
    </section>
  );
}

export default Section10;
