/** @format */

import React from "react";
import { Link } from "react-router-dom";

function To1() {
  return <Link to="/terms-of-use">სარგებლობის წესები</Link>;
}

export default To1;
