/** @format */

import React from "react";
import { Link } from "react-router-dom";

function To3() {
  return <Link to="/login">შესვლა</Link>;
}

export default To3;
