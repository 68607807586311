/** @format */

import React from "react";

function Marquee() {
  return (
    <div className="marquee-main">
      <div className="marquee--inner">
        <span>
          <div>
            <img src="img/hero/marquee/photo-1.svg" alt="bounce.ge" />
            <img src="img/hero/marquee/photo-3.svg" alt="ბაუნსი" />
            <img src="img/hero/marquee/photo-4.svg" alt="ბაუნს" />
            <img src="img/hero/marquee/photo-5.svg" alt="bounce" />
            <img src="img/hero/marquee/photo-6.svg" alt="დიზაინის გაკეთება" />
            <img src="img/hero/marquee/photo-7.svg" alt="ვებ დიზაინი" />
            <img src="img/hero/marquee/photo-8.svg" alt="გრაფიკული დიზაინი" />
          </div>
        </span>
        <span>
          <div>
            <img src="img/hero/marquee/photo-1.svg" alt="ბაუნსის პარტნიორი" />
            <img
              src="img/hero/marquee/photo-3.svg"
              alt="bounce.ge-ს პარტნიორი"
            />
            <img src="img/hero/marquee/photo-4.svg" alt="ბაუნსის პარტნიორი" />
            <img src="img/hero/marquee/photo-5.svg" alt="ბაუნსის პარტნიორი" />
            <img src="img/hero/marquee/photo-6.svg" alt="ბაუნსის პარტნიორი" />
            <img src="img/hero/marquee/photo-7.svg" alt="ბაუნსის პარტნიორი" />
            <img src="img/hero/marquee/photo-8.svg" alt="ბაუნსის პარტნიორი" />
          </div>
        </span>
      </div>
    </div>
  );
}

export default Marquee;
