/** @format */

import React, { useState } from "react";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqData = [
    {
      question: "არ მირჩევნია დიზაინერი დავიქირავო სრულ განაკვეთზე?",
      answer:
        "კარგი შეკითხვაა! სიმართლე ის არის, რომ სენიორ დიზაინერის დაქირავება საკმაოდ დიდ თანხებთან არის დაკავშირებული, ჩვეულებრივ 5,000₾ ან ბევრად მეტი ჯდება ყოველთვიურად მისი ანაზღაურება. არ უნდა დაგვავიწყდეს ბონუსებიც. ასეთი ბიუჯეტი, განსაკუთრებით ქართული ბაზრის პირობებში, მიუწვდომელია მცირე ბიზნესისა და სტარტაპებისთვის. ამას გარდა, ადვილად შესაძლებელია რომ არ გქონდეთ სისტემატიური დავალება დიზაინერისთვის და ხშირად მთელი წლის მანძილზე რამდენიმეჯერ გამოიყენოთ მისი რესურსი, რაც თავის მხრივ გაფლანგულ დროსა და ფინანსებს ნიშნავს.<br/><br/>ჩვენი ყოველთვიური გადახდის გეგმა საშუალებას გაძლევთ მხოლოდ იმ თვეებში ისარგებლოთ სერვისით, როდესაც დიზაინ პროექტები გაქვთ და ამგვარად მიიღოთ საუკეთესო ხარისხიც და თანხაც დაზოგოთ.",
    },
    {
      question: "მაქსიმუმ რამდენი მოთხოვნა შემიძლია გამოვგზავნო?",
      answer:
        "სერვისის შეძენის შემდეგ, შეგიძლიათ ულიმიტო რაოდენობის მოთხოვნა გამოგზავნოთ, რომელიც დაემატება რიგში და მისი შესრულება მოხდება ერთ ჯერზე ერთის პრინციპით. ხოლო თუ შეძენილი გაქვთ დამატება '1 x 2', შესრულდება ერთ ჯერზე ორის პრინციპით (რაც გულისხმობს ერთდროულად ორ დავალებაზე მუშაობას).",
    },
    {
      question: "რამდენ ხანში იქნება მზად?",
      answer:
        "ჩვენი მიზანია მაქსიმალურად სწრაფად და ეფექტურად შევასრულოთ დავალება. მაქსიმუმ 2 სამუშაო დღე სჭირდება სტანდარტული დავალების შესრულებას. შედარებით რთული დავალებები კი მეტ დროს მოითხოვს.",
    },
    {
      question: "რომელ პროგრამებს იყენებთ?",
      answer: "დავალებების უმეტესობას ვაკეთებთ Adobe XD-სა და Figma-ში.",
    },
    {
      question: "როგორ მოვითხოვო დიზაინის გაკეთება?",
      answer:
        "ჩვენ გთავაზობთ დავალების მოწოდების მრავალ მეთოდს პლატფორმა Trello-ს გამოყენებით. შეგიძლიათ პირდაპირ Trello-ზე აღწეროთ დავალება, გაგვიზიაროთ Google დოკუმენტი ან მიაბათ ნებისმიერი ტიპის ფაილი სადაც აღწერილი იქნება ტექნიკური დავალება. მათ შორის შეგიძლიათ საორიენტაციოდ ვიდეოც კი გადაიღოთ და განათავსოთ ტრელოზე. რომ შევაჯამოთ Trello-ს მეშვეობით შეგიძლიათ თქვენ და თქვენს თანამშრომლებს მოგვაწოდოთ დავალება ისე, როგორც თქვენთვის არის კომფორტული.",
    },
    {
      question: "და თუ დიზაინი არ მომწონს?",
      answer:
        "თუ თქვენ სრულად არ ხართ კმაყოფილი შედეგით, თქვენთან ერთად ჩავატარებთ პროექტის რევიზიას და ვიმუშავებთ იქამდე სანამ 100%-ით კმაყოფილი არ იქნებით.",
    },
    {
      question: "თუ სერვისი არ მომეწონა თანხას დამიბრუნებთ უკან?",
      answer:
        "ბაუნსში ჩვენ იმით ვამაყობთ, რომ მომხმარებელს ვაწვდით უმაღლესი ხარისხის სერვისს, რომელიც ხშირად აჭარბებს მოლოდინს. იმის გამო, რომ ძალიან ბევრ დროსა და ენერგიას ვახმართ ჩვენს სამუშაოს, ჩვენ არ გვაქვს თანხის უკან დაბრუნების პოლიტიკა.",
    },
  ];

  return (
    <div className="faq-section fade-in-from-bottom35">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className={`faq-item ${activeIndex === index ? "active" : ""}`}
          onClick={() => toggleAccordion(index)}
        >
          <div className="faq-question">
            <span>{faq.question}</span>
            <span className={`arrow ${activeIndex === index ? "active" : ""}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0)">
                  <path
                    d="M12 19.5C11.6729 19.5004 11.3495 19.431 11.0513 19.2965C10.7532 19.162 10.4871 18.9655 10.271 18.72L0.459956 7.568C0.149866 7.19846 -0.00333413 6.72225 0.0331156 6.24122C0.0695652 5.76018 0.292771 5.3125 0.655006 4.9939C1.01724 4.67529 1.48975 4.51105 1.9715 4.5363C2.45326 4.56155 2.90601 4.77428 3.23296 5.129L11.812 14.881C11.8354 14.9078 11.8643 14.9292 11.8968 14.9439C11.9292 14.9586 11.9644 14.9662 12 14.9662C12.0356 14.9662 12.0707 14.9586 12.1032 14.9439C12.1356 14.9292 12.1645 14.9078 12.188 14.881L20.767 5.129C20.9252 4.94043 21.1195 4.78539 21.3385 4.67299C21.5575 4.5606 21.7968 4.49312 22.0423 4.47452C22.2877 4.45592 22.5345 4.48657 22.7679 4.56469C23.0013 4.6428 23.2168 4.76678 23.4017 4.92936C23.5865 5.09194 23.737 5.28983 23.8443 5.5114C23.9515 5.73297 24.0134 5.97374 24.0263 6.21957C24.0392 6.4654 24.0028 6.71133 23.9193 6.94289C23.8358 7.17446 23.7068 7.38699 23.54 7.568L13.732 18.716C13.5154 18.9621 13.249 19.1593 12.9504 19.2944C12.6517 19.4296 12.3278 19.4997 12 19.5V19.5Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
          <div className="faq-answer">
            {activeIndex === index && (
              <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
