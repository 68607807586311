/** @format */

import React from "react";

function Section9() {
  return (
    <section id="section9">
      <div className="main-div sct9">
        <h2 className="h2title maxwidth600 fade-in-from-bottom27">
          UI/UX, გრაფიკული დიზაინი და სხვა
        </h2>

        <div className="sct9-main-div fade-in-from-bottom28">
          <img
            className="sct9svg1  fade-in-from-left3"
            src="icons/sct9-1.svg"
            alt="logos, apps, websites and more"
          />
          <div className="sct9-div">
            <div className="sct9-main-item">
              <div className="sct9-item">
                <p>ვებ აპლიკაციები</p>
              </div>

              <div className="sct9-item">
                <p>მობილური ინტერფეისები</p>
              </div>

              <div className="sct9-item">
                <p>ვიზუალური იდენტობა</p>
              </div>

              <div className="sct9-item">
                <p>დიზაინის ფრეიმვორქები</p>
              </div>

              <div className="sct9-item">
                <p>SAAS</p>
              </div>

              <div className="sct9-item">
                <p>ინტერფეისის მაკეტები</p>
              </div>

              <div className="sct9-item">
                <p>კორპორაციული კანცელარია</p>
              </div>

              <div className="sct9-item">
                <p>საპრეზენტაციო ვიზუალი</p>
              </div>
            </div>

            <div className="sct9-main-item">
              <div className="sct9-item">
                <p>მონაცემთა ვიზუალიზაცია</p>
              </div>

              <div className="sct9-item">
                <p>ბროშურები და ფლაერები</p>
              </div>

              <div className="sct9-item">
                <p>ნიშნები</p>
              </div>

              <div className="sct9-item">
                <p>ბრენდინგის სახელმძღვანელოები</p>
              </div>

              <div className="sct9-item">
                <p>სოციალური მედიის ვიზუალი</p>
              </div>

              <div className="sct9-item">
                <p>ფართომასშტაბიანი რეკლამები</p>
              </div>

              <div className="sct9-item">
                <p>საგამოფენო ბანერები</p>
              </div>

              <div className="sct9-item">
                <p>ელ.ფოსტის მარკეტინგის გრაფიკა</p>
              </div>
            </div>

            <div className="sct9-main-item">
              <div className="sct9-item">
                <p>CV დიზაინი</p>
              </div>

              <div className="sct9-item">
                <p>პირადი სავიზიტო ბარათები</p>
              </div>

              <div className="sct9-item">
                <p>პროდუქტის შეფუთვა</p>
              </div>

              <div className="sct9-item">
                <p>ბლოგ-პოსტის გრაფიკა</p>
              </div>

              <div className="sct9-item">
                <p>ვებსაიტები</p>
              </div>

              <div className="sct9-item">
                <p>ციფრული რეკლამა</p>
              </div>

              <div className="sct9-item">
                <p>აიკონის დიზაინი</p>
              </div>

              <div className="sct9-item">
                <p>პირდაპირი მარკეტინგის მასალები</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section9;
