/** @format */

import React from "react";

function Section5() {
  return (
    <section id="section5">
      <img
        className="sct5svg1 fade-in-from-bottom5"
        src="icons/sct5-1.svg"
        alt="uxline membership icon"
      />

      <img
        className="sct5svg2 fade-in-from-top"
        src="icons/sct5-2.svg"
        alt="line icon"
      />

      <img
        className="sct5svg3 fade-in-from-bottom6"
        src="icons/sct5-3.svg"
        alt="uxline.co icon"
      />

      <div className="main-div sct5">
        <div className="title-div fade-in-from-bottom5">
          <h2 className="h2title maxwidth800">
            ერთხელ თუ<span> </span>
            <span className="title-underline">გამოცდი</span>, მერე უარს ვეღარ
            იტყვი
          </h2>

          <p className="h2subtitle maxwidth800">
            ბაუნსი თამაშის ახალ წესებს ამკვიდრებს, რომელიც თავიდან გარიდებთ
            არასანდო დიზაინერებთან და სააგენტოებთან ურთიერთობას. ამ ყველაფერს
            ყოველთვიური გამოწერის სერვისითა და საქმის სწრაფი შესრულებით
            ახერხებს.
          </p>
        </div>

        <div className="sct5-div">
          <div className="sct5-layout fade-in-from-bottom9">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="30"
              viewBox="0 0 35 30"
              fill="none"
            >
              <path
                d="M22.3838 27.6777C23.5264 28.9961 25.3721 29.6992 27.4814 29.6992C31.6123 29.6992 34.249 26.9746 34.249 22.7559C34.249 18.625 31.5244 15.6367 27.6572 15.6367C26.8662 15.6367 25.9873 15.8125 25.1084 16.0762C24.5811 9.48438 27.833 4.03516 32.2275 2.36523L31.7881 0.871094C24.2295 3.77148 19.4834 11.1543 19.4834 19.8555C19.4834 22.668 20.5381 25.7441 22.3838 27.6777ZM0.499023 19.8555C0.499023 24.6895 3.22363 29.6992 8.49707 29.6992C12.54 29.6992 15.1768 26.9746 15.1768 22.7559C15.1768 18.625 12.4521 15.6367 8.67285 15.6367C7.88184 15.6367 7.00293 15.8125 6.12402 16.0762C5.59668 9.48438 8.84863 4.03516 13.2432 2.36523L12.7158 0.871094C5.24512 3.77148 0.499023 11.1543 0.499023 19.8555Z"
                fill="#EA552B"
              />
            </svg>

            <p>დიზაინი მარტივია. ამიტომ არის მისი შექმნა რთული.</p>

            <div className="sct5-qt">
              <div className="sct5-qt-img">
                <img src="img/recent-work/paul-rand.jpg" alt="Paul Rand" />
              </div>

              <p>
                პოლ რენდი,
                <span> </span>
                <span>
                  🎨
                  <strong>IBM</strong>
                </span>
              </p>
            </div>
          </div>

          <div className="sct5-layout2">
            <div className="notmal-txt-div fade-in-from-bottom10">
              <p className="normal-title">სრულად ასინქრონული</p>
              <p className="normal-txt">
                გძულს შეხვედრები? ჩვენც. ამიტომ მათზე უარი ვთქვით.
              </p>
            </div>

            <div className="notmal-txt-div fade-in-from-bottom11">
              <p className="normal-title">მართე TRELLO-ს მეშვეობით</p>
              <p className="normal-txt">
                თვალი ადევნე შენი დიზაინის პროგრესს Trello-ს დახმარებით.
              </p>
            </div>

            <div className="notmal-txt-div fade-in-from-bottom12">
              <p className="normal-title">ულიმიტო წევრები</p>
              <p className="normal-txt">
                გაამარტივე კოლაბორაცია, მოიწვიე შენი თანამშრომლები, თვალი
                ადევნეთ პროგრესს და დაამატეთ ახალი დავალებები.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section5;
