/** @format */

import React from "react";
import { Link } from "react-router-dom";

function To4() {
  return (
    <Link className="mainNavLink" to="/login">
      შესვლა
    </Link>
  );
}

export default To4;
