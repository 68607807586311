/** @format */

import React from "react";
import { Link } from "react-router-dom";

const TermsOfUse = () => {
  return (
    <section id="login">
      <div className="main-login-div">
        <img className="lg4" src="icons/lg4.svg" alt="fast" />
        <img className="lg5 termss" src="icons/lg5.svg" alt="gum" />

        <div className="login-upper">
          <Link className="login-back" to="/">
            <svg
              id="svgexport-17_4_"
              data-name="svgexport-17 (4)"
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="24.621"
              viewBox="0 0 49 24.621"
            >
              <path
                id="Path_3019"
                data-name="Path 3019"
                d="M.324,138.895,11.46,127.759a1.114,1.114,0,1,1,1.57,1.57L3.8,138.572H47.886a1.114,1.114,0,0,1,0,2.227H3.8l9.232,9.232a1.114,1.114,0,1,1-1.57,1.57L.324,140.465a1.113,1.113,0,0,1,0-1.57Z"
                transform="translate(0 -127.369)"
              />
            </svg>
          </Link>

          <Link className="login-go" to="/login">
            <p>შესვლა</p>
          </Link>
        </div>

        <div className="login-down termss">
          <h4 className="login-title">სარგებლობის წესები</h4>

          <p className="login-text">
            სერვისით სარგებლობა შეუძლია ნებისმიერ ორგანიზაციასა თუ ფიზიკურ პირს
            გარდა აზარტული თამაშების სფეროს წარმომადგენლებისა!
            <br />
            <br />
            ჩვენი სერვისით სარგებლობისას თქვენ ავტომატურად ეთანხმებით ჩვენი
            სერვისით სარგებლობის წესებს, ასევე Google-ის
            <span> </span>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://policies.google.com/terms"
            >
              სარგებლობის წესებს
            </a>
            <span> და </span>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://policies.google.com/privacy"
            >
              კონფიდენციალურობის პოლიტიკას
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
};

export default TermsOfUse;
